import React, {useEffect, useState} from 'react';
import {Modal} from "antd";

const GameOverModal = ({players, isGameOver}) => {
  const [visible, setVisible] = useState(false);
  const playersSortedFromHighestToLowestScore = Object.values(players).sort((a, b) => {
    if (a.score === b.score) {
      return 0;
    }
    return a.score < b.score ? 1 : -1;
  });

  const winner = playersSortedFromHighestToLowestScore[0];

  const getPlayersTiedForHighestScore = () =>
    playersSortedFromHighestToLowestScore.slice(1, playersSortedFromHighestToLowestScore.length).filter(({score}) => score === winner.score);

  const isTie = getPlayersTiedForHighestScore().length > 0;

  useEffect(() => {
    setVisible(isGameOver)
  }, [isGameOver])

  const closeModal = () => setVisible(false);

  return (
    <Modal visible={visible} title="Game Over" onOk={closeModal} onCance={closeModal}>
      {isTie
        ? <p>We Have a tie!</p>
        : <p>{`Congratulations, ${winner.username}!`}</p>
      }
      {
        playersSortedFromHighestToLowestScore.map(({username, score}) => <p key={username + score}>{`${username}: ${score}`}</p>)
      }
    </Modal>
  )
}

export default GameOverModal;
