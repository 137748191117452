import '../board.css';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useDictionary from '../dictionary';
import {LETTER_VALUES, BOARD_WIDTH, BOARD_HEIGHT} from '../Constants/GameConstants';
import {getNewTiles, shuffleArray} from '../Utility/GameLogic';
import {Button, Space} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';

const WORD_MIN_LENGTH = 2;
const RACK_START_INDEX = BOARD_WIDTH * BOARD_HEIGHT;
const RACK_SIZE = 7;
const STARTING_TILE_POSITION = 112;

const getX = index => index % BOARD_WIDTH;
const getY = index => Math.floor(index / BOARD_WIDTH);
const getIndex = (x, y) => y * BOARD_WIDTH + x;

const getWord = ({
  board,
  startIndex,
  isVertical
}) => {
  let word = '';
  let index = startIndex;

  while (board[index] != null) {
    word += board[index];
    index = getNextAdjacentIndex(index, isVertical);
  }
  return word;
};

const getWordScore = ({word, startIndex, tilePositions, isVertical}) => {
  if (!word || word.length < WORD_MIN_LENGTH) {
    return 0;
  }

  let isNewWord = false;
  let score = 0;
  let index = startIndex;

  word.split('').forEach(letter => {
    if (tilePositions[index] != null) {
      isNewWord = true;
    }
    score += LETTER_VALUES[letter];
    index = getNextAdjacentIndex(index, isVertical);
  });
  return isNewWord ? score : 0;
};

const getNextAdjacentIndex = (index, isVertical) => {
  let x = getX(index);
  let y = getY(index);
  if (isVertical) {
    const adjIndex = getIndex(x, y + 1);
    return adjIndex >= RACK_START_INDEX ? undefined : adjIndex;
  }
  const adjIndex = getIndex(x + 1, y);
  return getY(adjIndex) > y ? undefined : adjIndex;
}

const getMove = ({initialBoard, tilePositions, isWord}) => {
  const flattenedBoard = new Array(BOARD_HEIGHT * BOARD_WIDTH).fill(null);
  Object.keys(initialBoard).forEach(letterPosition => flattenedBoard[letterPosition] = initialBoard[letterPosition]);
  if (!isLegalMove(initialBoard, tilePositions)) {
    return {words: [], score: 0}; 
  }
  const combinedBoardArray = getCombinedBoardArray(initialBoard, tilePositions);
  const words = [];
  let score = 0;

  combinedBoardArray.forEach((letter, index) => {
    if (letter == null) {
      return words;
    }
    const x = getX(index);
    const y = getY(index);
    const horizontalWord = combinedBoardArray[getIndex(x - 1, y)] == null && getWord({board: combinedBoardArray, startIndex: index, isVertical: false});
    const horizontalScore = getWordScore({word: horizontalWord, startIndex: index, tilePositions, isVertical: false});
    const verticalWord = combinedBoardArray[getIndex(x, y - 1)] == null && getWord({board: combinedBoardArray, startIndex: index, isVertical: true});
    const verticalScore = getWordScore({word: verticalWord, startIndex: index, tilePositions, isVertical: true});
    if (horizontalScore > 0) {
      words.push(horizontalWord);
      score += horizontalScore;
    }
    if (verticalScore > 0) {
      words.push(verticalWord);
      score += verticalScore;
    }
  });

  if (!words.every(isWord)) {
    return {words: [], score: 0};
  }

  if (score > 0) {
    score = score * getScoreMultiplier(tilePositions);
  }
  return {words, score};
};

const getScoreMultiplier = tilePositions => {
  return Object.keys(tilePositions).filter(pos => pos < RACK_START_INDEX && tilePositions[pos] != null).length;
}

const getInitialTilePositions = tiles => {
  return tiles.reduce((positions, tile, index) => ({...positions, [index + RACK_START_INDEX]: tile}), {});
};

const getTileRackArray = tilePositions => {
  return [...(new Array(RACK_SIZE + 1)).keys()].map(key => (tilePositions[key + RACK_START_INDEX] || null));
};

const getCombinedBoardArray = (initialBoard, tilePositions) => {
  return [...(new Array(BOARD_WIDTH * BOARD_HEIGHT)).keys()].map(key => (tilePositions[key] || initialBoard[key] || null));
}

const isLegalMove = (initialBoard, tilePositions) => {
  const placedTiles = Object.keys(tilePositions).filter(pos => pos < RACK_START_INDEX);
  if (placedTiles.length == 0) {
    return false;
  }
  if (!placedTiles.some(pos => hasAdjacentTile(initialBoard, pos)) && !isValidFirstMovePosition(initialBoard, tilePositions)) {
    return false;
  }
  let previousX = getX(placedTiles[0]);
  let previousY = getY(placedTiles[0]);
  let isHorizontal = false;
  let isVertical = false;

  return placedTiles.every(pos => {
    const x = getX(pos);
    const y = getY(pos);
    if (x !== previousX) {
      isHorizontal = true;
    }
    if (y !== previousY) {
      isVertical = true;
    }
    if (isHorizontal && isVertical) {
      return false;
    }
    if (
      isHorizontal
      && x - previousX > 1
      && getWord({board: initialBoard, startIndex: getIndex(previousX + 1, y), isVertical: false}).length < (x - previousX - 1)
    ) {
      return false;
    }
    if (
      isVertical
      && y - previousY > 1
      && getWord({board: initialBoard, startIndex: getIndex(x, previousY + 1), isVertical: true}).length < (y - previousY - 1)
    ) {
      return false;
    }
    previousX = x;
    previousY = y;
    return true;
  });
};

const hasAdjacentTile = (board, index) => {
  const x = getX(index);
  const y = getY(index);
  return board[getIndex(x - 1, y)] != null
      || board[getIndex(x + 1, y)] != null
      || board[getIndex(x, y - 1)] != null
      || board[getIndex(x, y + 1)] != null;
};

const isValidFirstMovePosition = (initialBoard, tilePositions) => (
  Object.keys(initialBoard).length === 0 && tilePositions[STARTING_TILE_POSITION] != null
);

export const initBoard = () => {
  return {22: 'J', 37: 'U', 52: 'S', 67: 'T', 82: 'I', 97: 'C', 112: 'E', 124: 'C', 125: 'H', 126: 'E', 127: 'S', 128: 'S'};
};

const Board = ({initialBoard, newTiles, handleSubmitTurn, isActivePlayerTurn, hasGameBegun}) => {
  const [tilePositions, setTilePositions] = useState(getInitialTilePositions(newTiles));
  const [dragIndex, setDragIndex] = useState(null);
  const isWord = useDictionary();
  const {words, score} = getMove({initialBoard, tilePositions, isWord});
  
  const moveTile = destinationIndex => {
    if (dragIndex == null || initialBoard[destinationIndex] != null || tilePositions[destinationIndex] != null) {
      return;
    }
    const {[dragIndex.toString()]: draggedTile, ...remainingTiles} = tilePositions;
    if (draggedTile) {
      setTilePositions({...remainingTiles, [destinationIndex]: draggedTile});
    }
    setDragIndex(null);
  };

  const submitTurn = () => {
    const hand = Object.keys(tilePositions)
      .filter(index => index >= RACK_START_INDEX)
      .map(index => tilePositions[index]);

    handleSubmitTurn({
      hand,
      newBoard: getCombinedBoardArray(initialBoard, tilePositions),
      playedWords: words,
      score,
    })
  }

  useEffect(() => {
    setTilePositions(getInitialTilePositions(newTiles));
  }, [newTiles])

  return (
    <>
      <b>Score: {score}</b>
      <ul>
        {words.map((word, i) => (
          <li key={i}>{word}</li>
        ))}
      </ul>
      <Space align="center" size="middle">
        <div className="grid rack">
          {getTileRackArray(tilePositions).map((letter, index) => (
            <Cell index={index + RACK_START_INDEX} key={index} letter={letter} handleDrag={setDragIndex} handleDrop={moveTile} isDraggable={letter != null} />
          ))}
        </div>
        <Button onClick={() => setTilePositions(getInitialTilePositions(shuffleArray(newTiles)))} size="large" shape="circle" icon={<ReloadOutlined />} title="Shuffle" />
        <Button disabled={!hasGameBegun || !isActivePlayerTurn || score === 0} onClick={submitTurn}>Submit Turn</Button>
      </Space>
      <div className="grid">
        {getCombinedBoardArray(initialBoard, tilePositions).map((letter, index) => (
          <Cell index={index} key={index} letter={letter} handleDrag={setDragIndex} handleDrop={moveTile} isDraggable={tilePositions[index] != null}/>
        ))}
      </div>
    </>
  );
}

Board.propTypes = {
  initialBoard: PropTypes.objectOf(PropTypes.string),
  newTiles: PropTypes.arrayOf(PropTypes.string)
};

Board.defaultProps = {
  initialBoard: {},
  newTiles: []
}

const Cell = ({index, letter, isDraggable, handleDrag, handleDrop}) => {
  const onDrop = e => {
    e.preventDefault();
    handleDrop(index);
  };
  const onDragStart = e => {
    const tileCenter = e.currentTarget.offsetWidth / 2;
    e.dataTransfer.setDragImage(e.currentTarget, tileCenter, tileCenter);
    e.dataTransfer.setData('text', `tile${index}`);
    handleDrag(index);
  };
  return (
    <div className="cell" onDragOver={e => e.preventDefault()} onDragEnter={e => e.preventDefault()} onDrop={onDrop}>
      {letter != null && (
        <div className="tile" draggable={isDraggable} onDragStart={onDragStart}>
          {letter} <div className="value">{LETTER_VALUES[letter]}</div>
        </div>
      )}
    </div>
  );
};

Cell.propTypes = {
  index: PropTypes.number.isRequired,
  letter: PropTypes.string,
  isDraggable: PropTypes.bool.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired
}

export default Board;
