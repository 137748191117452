import React, {useState, useEffect} from 'react';
import 'firebase/database';
import { useDatabase } from 'reactfire';
import {Card, Input, Button, Space} from 'antd';
import {GAME_LETTERS} from '../Constants/GameConstants';
import {shuffleArray} from '../Utility/GameLogic';

const LandingPage = ({gameId, setActiveGameId, setActivePlayerId}) => {
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gameId && username) {
      joinGame(gameId);
    }
  }, [gameId]);

  const handleUsernameChange = e => {
    const username = e.target.value;
    setUsername(username);
  }

  const database = useDatabase();
  const gamesRef = database.ref('games');

  const createGame = () => {
    setIsLoading(true);
    const newGame = {
      turnCount: 0,
      players: [],
      letterPool: shuffleArray([...GAME_LETTERS]),
      board: {},
      hasBegun: false,
      isComplete: false,
      dateCreated: (new Date()).getTime()
    };

    const newRef = gamesRef.push();
    newRef.set(newGame, (err) => {
      addPlayer(newRef, true);
    });
  };

  const addPlayer = (gameRef, isOwner = false) => {
    const playerRef = gameRef.child('players').push();
    const id = playerRef.key;
    playerRef.set({
      username,
      hand: {},
      playedWords: {},
      score: 0,
      isOwner,
      id
    }).then(() => {
      setActivePlayerId(playerRef.key);
      setActiveGameId(gameRef.key);
      setIsLoading(false);
    });
    localStorage.setItem('username', username);
  }

  const joinGame = (newGameId) => {
    setIsLoading(true);
    const selectedGameRef = gamesRef.child(newGameId);
    selectedGameRef.once('value', snapshot => {
      if (!snapshot.exists()) {
        alert(`Game "${newGameId}" doesn't exist.`);
        setIsLoading(false);
        return;
      }
      if (snapshot.val().hasBegun) {
        alert('This game has begun, no new players allowed!');
        setIsLoading(false);
        return;
      }
      addPlayer(selectedGameRef);
    });
  }

  return (
    <Card>
      <Space>
        <Input placeholder="Enter user name" value={username} onChange={handleUsernameChange} disabled={isLoading} />
        <Button type="primary" disabled={!username} onClick={() => gameId ? joinGame(gameId) : createGame()} loading={isLoading}>
          {gameId ? 'Join Game' : 'Create Game'}
        </Button>
      </Space>
    </Card>
  );
};

export default LandingPage;
