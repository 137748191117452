import React, {useEffect, useState} from 'react';
import '../app.css';
import 'firebase/database';
import 'firebase/storage';
import {useDatabase, useDatabaseObjectData, useStorage} from 'reactfire';
import {Button, Divider, Layout, PageHeader, Typography} from 'antd';
const {Content} = Layout;
import Board, {initBoard} from './Board';
import LandingPage from './LandingPage';
import GameLobby from "./GameLobby";
import {drawHand, getNewTiles, isOver} from "../Utility/GameLogic";
import GameOverModal from "./GameOverModal";
import GameDetails from "./GameDetails";
import {URL_GAME_PREFIX} from '../Constants/GameConstants';

export const setLocalStorage = (key, value) => {
  value == null ? localStorage.removeItem(key) : localStorage.setItem(key, value);
};

const audioReference = new Audio();

function App() {
  const [activeGameId, setActiveGameId] = useState('');
  const [activePlayerId, setActivePlayerId] = useState('');
  const [hasGameBegun, setHasGameBegun] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);

  const database = useDatabase();
  const storage = useStorage();
  const {data: activeGame} = useDatabaseObjectData(database.ref(`games/${activeGameId}`), {idField: 'id'});
  const {data: activePlayer} = useDatabaseObjectData(database.ref(`games/${activeGameId}/players/${activePlayerId}`), {idField: 'id'});

  const handleLeaveGame = () => {
    handleSetActiveGameId(null);
    handleSetActivePlayerId(null);
    setLocalStorage('hasGameBegun', null);
    setIsGameOver(false);
    if (activePlayer?.isOwner) {
      database.ref(`games/${activeGame?.id}`).set(null);
    } else {
      database.ref(`games/${activeGame?.id}/players/${activePlayer?.id}`).set(null);
    }
    history.pushState({}, '', '/');
  }

  const handleSetActiveGameId = id => {
    setActiveGameId(id)
    setLocalStorage('activeGameId', id);
    if (id) {
      history.pushState({}, '', URL_GAME_PREFIX + id);
    }
  }

  const handleSetActivePlayerId = id => {
    setActivePlayerId(id);
    setLocalStorage('activePlayerId', id);
  }

  const isActiveGame = !!activeGameId && !!activePlayerId;

  const handleStartGame = () => {
    const gameRef = database.ref(`games/${activeGameId}`);

    let letterPool = [...activeGame.letterPool];
    Object.keys(activeGame.players).forEach(playerId => {
      const {newLetterPool, newHand} = drawHand(letterPool);
      gameRef.child(`players/${playerId}/hand`).set(newHand);
      letterPool = newLetterPool;
    })

    gameRef.child('letterPool').set(letterPool);

    setHasGameBegun(true);
  }

  const handleSubmitTurn = ({hand, newBoard, playedWords, score}) => {
    const game = database.ref(`games/${activeGameId}`);
    const {newLetterPool, newHand} = drawHand(activeGame?.letterPool || [], hand);

    game.update({
      board: newBoard,
      lastTurn: {
        username: activePlayer.username,
        words: playedWords,
        score
      },
      letterPool: newLetterPool,
      turnCount: activeGame.turnCount + 1
    });

    const player = database.ref(`games/${activeGameId}/players/${activePlayerId}`);
    playedWords.forEach(word => {
      const wordRef = player.child('playedWords').push();
      wordRef.set(word)
    })

    player.update({
      hand: newHand,
      score: activePlayer.score + score
    })
  }

  const getAtBatPlayerId = () => {
    if (!activeGame?.players) {
      return;
    }
    const playerKeys = Object.keys(activeGame.players);
    const numPlayers = playerKeys.length;
    const playerTurnNumber = Math.floor(activeGame.turnCount % numPlayers);

    return playerKeys[playerTurnNumber];
  }

  const isActivePlayerTurn = getAtBatPlayerId() === activePlayerId;

  useEffect(() => {
    const gameId = localStorage.getItem('activeGameId');
    if (gameId) {
      setActiveGameId(gameId);
      history.replaceState({}, '', URL_GAME_PREFIX + gameId);
    } else {
      setGameIdFromUrl();
    }
    setActivePlayerId(localStorage.getItem('activePlayerId'));
    storage.ref('notify.mp3').getDownloadURL().then(url => audioReference.src = url);
  }, []);

  useEffect(() => {
    setHasGameBegun(activeGame?.hasBegun)
    const players = activeGame?.players ? Object.values(activeGame.players) : [];

    if (activeGame?.hasBegun) {
      // hard coded letterPool to prevent false positive
      const letterPool = activeGame ? activeGame?.letterPool || [] : ['a'];
      const hands = players.map(player => player?.hand ? player.hand : []);

      if (isOver(letterPool, hands)) {
        setIsGameOver(true);
      } else if (isActivePlayerTurn) {
        audioReference.play();
      }
    }
  }, [activeGame])

  const getTurnText = () => {
    if (!isActiveGame || !hasGameBegun) {
      return '';
    }

    if (isActivePlayerTurn) {
      return "It's your turn!"
    }
    const atBatPlayer = activeGame?.players && activeGame?.players[getAtBatPlayerId()];

    return `Waiting for ${atBatPlayer?.username}...`;
  }

  const setGameIdFromUrl = () => {
    if (location.pathname.substring(0, URL_GAME_PREFIX.length) !== URL_GAME_PREFIX) {
      return;
    }
    const newGameId = location.pathname.substring(URL_GAME_PREFIX.length);
    if (newGameId.length > 0) {
      setActiveGameId(newGameId);
    }
  }

  return (
    <div className="App">
      <Layout className="layout">
        <Content className="App-body">
          <PageHeader
            className="site-page-header"
            title="Crabbles"
            subTitle={activePlayerId ? `Welcome, ${activePlayer?.username}` : ''}
            extra={[
              isActiveGame && <Button key='leaveGame' danger onClick={handleLeaveGame}>Leave Game</Button>
            ]}
          />
          {isActiveGame ?
            <GameLobby hasGameBegun={hasGameBegun} activePlayer={activePlayer} gameId={activeGameId} handleStartGame={handleStartGame} handleLeaveGame={handleLeaveGame} />
            : <LandingPage setActiveGameId={handleSetActiveGameId} setActivePlayerId={handleSetActivePlayerId} gameId={activeGameId} />
          }
          <Divider />
          {isActiveGame && <GameDetails game={activeGame} turnText={getTurnText()} />}
          <Board
            initialBoard={isActiveGame ? (activeGame?.board || {}) : {}}
            newTiles={activePlayer ? activePlayer.hand : []}
            activePlayer={activePlayer || null}
            handleSubmitTurn={handleSubmitTurn}
            isActivePlayerTurn={isActivePlayerTurn}
            hasGameBegun={hasGameBegun}
          />
          {isGameOver && <GameOverModal players={activeGame?.players || {}} isGameOver={isGameOver}/>}
        </Content>
      </Layout>
    </div>
  );
}

export default App;
