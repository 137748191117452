import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './Components/App';
import {FirebaseAppProvider} from 'reactfire';

const firebaseConfig = {
  apiKey: 'AIzaSyD3YINeS3AgWtWUlcfRwWQ0u7BXzturlaU',
  authDomain: 'scrabble-378f7.firebaseapp.com',
  databaseURL: 'https://scrabble-378f7-default-rtdb.firebaseio.com',
  projectId: 'scrabble-378f7',
  storageBucket: 'scrabble-378f7.appspot.com',
  messagingSenderId: '399643565182',
  appId: '1:399643565182:web:d7de506a5dd4750acccc2f',
  measurementId: 'G-ZGZNW3XFE6'
};

ReactDOM.render(
  (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  ),
  document.getElementById('root')
);