import {useDatabase, useDatabaseObjectData} from 'reactfire';

const findWord = (dictionary, word, start, end) => {
  if (dictionary == null || end < start) {
    return false;
  }
  const midpoint = Math.floor((start + end) / 2);
  if (word === dictionary[midpoint]) {
    return true;
  }
  if (word < dictionary[midpoint]) {
    return findWord(dictionary, word, start, midpoint - 1);
  }
  return findWord(dictionary, word, midpoint + 1, end);
};

export default function useDictionary() {
  const wordsRef = useDatabaseObjectData(useDatabase().ref('words'));
  if (wordsRef.data == null) {
    return () => false;
  }
  const dictionary = Object.values(wordsRef.data);
  return word => findWord(dictionary, word, 0, dictionary.length - 1);
}