import React, {useEffect} from 'react';
import 'firebase/database';
import { useDatabase } from 'reactfire';
import {Button, Typography} from 'antd';
const {Text} = Typography;

const GameLobby = ({hasGameBegun, gameId, activePlayer, handleStartGame, handleLeaveGame}) => {
  const database = useDatabase();

  useEffect(() => {
    if (!gameId) {
      return;
    }
    getGameRef().once('value', snapshot => {
      if (!snapshot.exists()) {
        handleLeaveGame();
      }
    });
  }, [gameId]);

  const getGameRef = () => {
    return database.ref(`games/${gameId}`)
  }

  const startGame = () => {
    getGameRef().update({hasBegun: true}).then(handleStartGame)
  } 

  return (
    <>
      {!hasGameBegun && <Text>{`Game id: ${gameId}`}</Text>}
      {!hasGameBegun && activePlayer?.isOwner && <Button onClick={startGame}>Start Game (Wait for all players to join)</Button>}
    </>
  );
};

export default GameLobby;
