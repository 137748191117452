import {HAND_COUNT} from "../Constants/GameConstants";

export const shuffleArray = input => {
  const array = [...input];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const drawHand = (letterPool, hand = []) => {
  if (letterPool.length === 0) {
    return {
      newLetterPool: letterPool,
      newHand: hand
    }
  }

  const newTileCount = HAND_COUNT - hand.length;

  const shuffledArray = shuffleArray([...letterPool]);

  return {
    newLetterPool: shuffledArray.slice(newTileCount),
    newHand: [...hand, ...shuffledArray.slice(0, newTileCount)]
  }
}

export const getNewTiles = () => {
  return ['L', 'E', 'A', 'G', 'U', 'E', 'S'];
};

export const isOver = (letterPool, hands) => {
  if (hands.length === 0) {
    return false;
  }
  const emptyHands = hands.filter(hand => hand.length === 0);
  return emptyHands.length > 0 && letterPool.length === 0;
}

export const getWinner = players => {
  const playerIds = Object.keys(players);
  let winner = players[playerIds[0]];
  for (let i = 1; i < playerIds.length; i++) {
    if (players[playerIds[i]].score > winner.score) {
      winner = players[playerIds[i]]
    }
  }

  return winner
}