import React from 'react';
import {Card, Table, Typography} from 'antd';

const playerDetailColumns = [
  {
    title: 'Name',
    dataIndex: 'username',
    key: 'name'
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score'
  }
]

const getWordsText = words => {
  const wordsCopy = [...words || ''];
  if (wordsCopy.length === 1) {
    return wordsCopy[0];
  }
  const lastTwoWordsText = wordsCopy.splice(wordsCopy.length - 2, 2).join(' and ');

  const firstWordsText = wordsCopy.length > 0 ? wordsCopy.join(', ') + ', ' : '';

  return firstWordsText + lastTwoWordsText;
}

const GameDetails = ({game, turnText}) => {
  const getLastTurnText = () => {
    if (!game?.lastTurn) {
      return ''
    }
    const {username, words, score} = game.lastTurn;
    return `${username} played ${getWordsText(words)} for ${score} points`
  }

  const dataSource = game?.players ? Object.values(game.players).map((player, idx) => ({...player, key: player.username + idx})) : [];

  return (
    <Card>
      <Table
        columns={playerDetailColumns}
        dataSource={dataSource}
        pagination={false}
      />
      <p>{getLastTurnText()}</p>
      <Typography.Text>{turnText}</Typography.Text>
    </Card>
  )
}

export default GameDetails;
